import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import Testimonial from "~sections/customer-stories/Testimonial"
import Hero from "~sections/project/Comparison/Hero"
import { Helmet } from "react-helmet"
import ComparisonChart from "~sections/project/Comparison/Comparison-chart/comparison-chart"

const header = {
    headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
    containerFluid:false,
    buttonBlock: (
      <HeaderButton
        className="ms-auto d-none d-xs-inline-flex"
        btnOneText="Login"
        btnTwoText="14 days Free Trial"
        mr="15px"
        mrLG="0"
      />
    ),
  }

  export default function Comparison() {
    return (
      <>
      <Helmet>
        
    </Helmet>
      <PageWrapper headerConfig={header}>
        <Hero />
        <ComparisonChart />
        <Testimonial />
        <CtaSection />
        <FooterOne />
      </PageWrapper>
      </>
    )
  }